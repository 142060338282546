import { Box, Image, Text } from "@chakra-ui/react";

export function ValentinesCard_2() {
  return (
    <Box
      p={8}
      bg={"#EDF2F7"}
      width={{ base: "full", md: 350, lg: 400 }}
      borderRadius={"lg"}
      boxShadow={{ base: "none", md: "2xl" }}
      mb={{ base: 16, md: 0 }}
    >
      <Image alt="" w={"full"} height={"auto"} src={"/valentines_2.png"} />

      <Box fontFamily="Charmonman" fontSize={"md"} color={"gray.900"} mt={4}>
        <Text fontSize={"lg"}>Happy Valentine’s Day Mom!</Text>
        <Text fontSize={"lg"} mt={8}>
          My love for you is non-fungible, just like this card.
        </Text>
        <Text fontSize={"lg"} mt={8}>
          Your son, Steven
        </Text>
      </Box>
    </Box>
  );
}
