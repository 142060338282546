import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { ValentinesCard_1 } from "~/components/Valentines/ValentinesCard_1";
import { ValentinesCard_2 } from "~/components/Valentines/ValentinesCard_2";
import NextLink from "next/link";
import { ValentinesCard_3 } from "~/components/Valentines/ValentinesCard_3";
import { ValentinesCard_4 } from "~/components/Valentines/ValentinesCard_4";
import { ValentinesCard_5 } from "~/components/Valentines/ValentinesCard_5";
import { ValentinesCard_6 } from "~/components/Valentines/ValentinesCard_6";
import { ValentinesCard_7 } from "~/components/Valentines/ValentinesCard_7";
import { FiArrowRight } from "react-icons/fi";
import useAuthContext from "~/context/auth.context";
import { toast } from "~/core/toast";
import { useRouter } from "next/router";
import { ecardPrices, localStorageKeys } from "~/core/constants";

import Head from "next/head";

function Valentines() {
  const { user } = useAuthContext();

  const cards = [
    <ValentinesCard_1 key={"v_card_1"} />,
    <ValentinesCard_2 key={"v_card_2"} />,
    <ValentinesCard_3 key={"v_card_3"} />,
    <ValentinesCard_4 key={"v_card_5"} />,
    <ValentinesCard_5 key={"v_card_6"} />,
    <ValentinesCard_6 key={"v_card_7"} />,
    <ValentinesCard_7 key={"v_card_8"} />,
  ];

  const [cardIdx, setCardIdx] = useState(0);
  const intervalRef = useRef<any>();
  const router = useRouter();

  useEffect(() => {
    const ref = router.query.ref;

    if (ref && typeof ref === "string") {
      try {
        // localStorage may not be available in some rare cases
        window.localStorage.setItem(localStorageKeys.ecardRefId, ref);
      } catch (err) {
        console.warn(err);
      }
    }
  }, [router.query.ref]);

  useEffect(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = undefined;
    }

    intervalRef.current = setInterval(() => {
      if (cardIdx >= cards.length - 1) {
        setCardIdx(0);
      } else {
        setCardIdx(cardIdx + 1);
      }
    }, 3000);
  }, [intervalRef, cardIdx, cards.length]);

  return (
    <Box marginX="auto" py={8} px={4} maxW={1200} mb={16}>
      <Head>
        <title>Valentine&apos;s Day NFT Cards</title>
        <meta
          name="description"
          content="Make your love last forever! Valentines on the blockchain now!"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          key="og:title"
          content={"Valentine's Day NFT Cards"}
        />
        <meta
          property="og:description"
          key="og:description"
          content="Make your love last forever! Valentines on the blockchain now!"
        />
        <meta
          property="og:image"
          key="og:image"
          content="https://hype.day/valentines_meta.png"
        />
        <meta property="og:url" key="og:url" content="https://nftcard.day" />
        <meta property="og:site_name" content="Hype.day" />
        <meta
          name="twitter:card"
          key="twitter:card"
          content="summary_large_image"
        />
        <meta name="twitter:site" key="twitter:site" content="@hypedotday" />
        <meta
          name="twitter:image:alt"
          content="Make your love last forever! Valentines on the blockchain now!"
        />
        <meta
          name="google-site-verification"
          content="ny2b9ZZGw-bFdKNADDkL48U1nPCLrxrLrutRAxfoGA0"
        />
      </Head>
      <Flex
        direction={{ base: "column-reverse", md: "row" }}
        pr={{ base: 0, xl: 128 }}
      >
        <Box flex={1} mr={{ base: 0, md: 8, lg: 36 }}>
          <Flex direction={"column"} display={{ base: "none", md: "flex" }}>
            <Text
              fontWeight={300}
              lineHeight={1.75}
              fontSize={{ md: "2xl", lg: "2.5xl" }}
            >
              Make your love last forever
            </Text>
            <Heading
              fontWeight={500}
              lineHeight={1.25}
              fontSize={{ md: "5xl", lg: "6xl" }}
            >
              Valentines on the blockchain
            </Heading>
          </Flex>

          <UnorderedList pl={0} mt={{ base: 8, md: 12, lg: 16 }}>
            <ListItem mb={6} lineHeight={1.75} fontSize={"lg"} fontWeight={300}>
              No crypto knowledge required. Your valentine doesn’t need a
              wallet, just an email
            </ListItem>
            <ListItem mb={6} lineHeight={1.75} fontSize={"lg"} fontWeight={300}>
              Upload your own photo or select a template
            </ListItem>
            <ListItem mb={6} lineHeight={1.75} fontSize={"lg"} fontWeight={300}>
              View your valentines easily at any time with just your email, no
              account required for you or your valentine
            </ListItem>
            <ListItem mb={6} lineHeight={1.75} fontSize={"lg"} fontWeight={300}>
              Just ${ecardPrices.standard} for a valentines NFT card on the
              Polygon blockchain!
            </ListItem>
            <ListItem mb={6} lineHeight={1.75} fontSize={"lg"} fontWeight={300}>
              Sending a bulk valentine? Additional recipients cost $
              {ecardPrices.discounted}
            </ListItem>
          </UnorderedList>

          <Flex direction={"column"}>
            <NextLink href={"/greeting-cards/creator"}>
              <Button
                type="submit"
                bg={"#BB48B6"}
                color={"gray.50"}
                _hover={{
                  bg: "#ac43a6",
                }}
                borderRadius={"xl"}
                boxShadow={"xl"}
                mx={{ base: 0, md: 16 }}
                size="lg"
                fontWeight={500}
              >
                Send a Valentine
              </Button>
            </NextLink>
            {user && (
              <NextLink href={"/greeting-cards/profile"}>
                <Button
                  mt={4}
                  borderRadius={"xl"}
                  mx={{ base: 0, md: 16 }}
                  size="lg"
                  fontWeight={500}
                >
                  My Cards
                </Button>
              </NextLink>
            )}

            {!user && (
              <Button
                mt={4}
                rightIcon={<Icon ml={2} as={FiArrowRight}></Icon>}
                onClick={() => {
                  toast({
                    status: "warning",
                    description: "Please login first.",
                  });
                }}
                borderRadius={"xl"}
                mx={{ base: 0, md: 16 }}
                size="lg"
                fontWeight={500}
              >
                My Cards
              </Button>
            )}
          </Flex>
        </Box>

        <Box>
          <Flex
            direction={"column"}
            display={{ base: "flex", md: "none" }}
            mb={8}
            p={4}
          >
            <Text
              fontWeight={300}
              lineHeight={1.75}
              fontSize={{ base: "xl", sm: "2xl" }}
            >
              Make your love last forever
            </Text>
            <Heading
              fontWeight={500}
              lineHeight={1.25}
              fontSize={{ base: "5xl", sm: "6xl" }}
            >
              Valentines on the blockchain
            </Heading>
          </Flex>

          <Box
            width={{ base: 350, md: 350, lg: 400 }}
            height={{ base: 650, md: 650, lg: 700 }}
            position={"relative"}
            mx={"auto"}
          >
            {cards.map((c, idx) => {
              return (
                <Box
                  position={"absolute"}
                  key={`card-idx-${idx}`}
                  opacity={idx === cardIdx ? 1 : 0}
                  transition={"opacity 1s ease-in-out"}
                >
                  {c}
                </Box>
              );
            })}
          </Box>
        </Box>
      </Flex>
    </Box>
  );
}

export default Valentines;
