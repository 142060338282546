import { Box, Image, Text } from "@chakra-ui/react";

export function ValentinesCard_7() {
  return (
    <Box
      p={8}
      bg={"#EDF2F7"}
      width={{ base: "full", md: 350, lg: 400 }}
      borderRadius={"lg"}
      boxShadow={{ base: "none", md: "2xl" }}
      mb={{ base: 16, md: 0 }}
    >
      <Image alt="" w={"full"} height={"auto"} src={"/valentines_7.png"} />

      <Box fontFamily="Charmonman" fontSize={"md"} color={"gray.900"} mt={4}>
        <Text fontSize={"lg"}>Babe,</Text>
        <Text fontSize={"lg"} mt={8}>
          I must be a magician, because every time I look at you, everyone else
          disappears.
        </Text>
        <Text fontSize={"lg"} mt={8}>
          Happy Valentine’s Day!
        </Text>
      </Box>
    </Box>
  );
}
