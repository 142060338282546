import { Box, Image, Text } from "@chakra-ui/react";

export function ValentinesCard_3() {
  return (
    <Box
      p={8}
      bg={"#EDF2F7"}
      width={{ base: "full", md: 350, lg: 400 }}
      borderRadius={"lg"}
      boxShadow={{ base: "none", md: "2xl" }}
      mb={{ base: 16, md: 0 }}
    >
      <Image alt="" w={"full"} height={"auto"} src={"/valentines_3.png"} />

      <Box fontFamily="Charmonman" fontSize={"md"} color={"gray.900"} mt={4}>
        <Text fontSize={"lg"}>To my loving and patient wife,</Text>
        <Text fontSize={"lg"} mt={8}>
          Markets are red, investors are blue, I lost all our money in FTX but I
          still love you {"<3"}
        </Text>
        <Text fontSize={"lg"} mt={8}>
          Love, Jesse
        </Text>
      </Box>
    </Box>
  );
}
